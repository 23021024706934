import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../../contexts/Context";
import axios from "axios";
import { IoCloseCircle } from "react-icons/io5";
import promotion from "../customerComponents/Promotion";

const AdvocadoPage = ({ show, setShow }) => {
    const [displayShow, setDisplay] = useState(show ? "grid" : "none");
    const [campaign, setCampaign] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex, token, cart, redeem, discount, finalOrder } = React.useContext(UserContext);
    const { adCustomer } = React.useContext(UserContext);
    const { addAdCustomer, addToCart, setRedeem, setDiscount, setFinalOrder } = React.useContext(ActionContext);

    useEffect(() => {
        setDisplay(show ? "flex" : "none");
        if (token) {
            axios.post(`/v2/api/selforder/advocado/redeem/available`,
                { adCustomer, redeem, cart, discount },
                {
                    headers: { Authorization: "Bearer " + token },

                })
                .then(function (response) {
                    if (response.status == 200) {
                        setCampaign(response.data.campaigns);
                        setUserInfo(response.data.userInfo);
                    }
                })
                .catch(function (response) {
                    //show warning
                })
            // setCampaign([
            //     {
            //         campaignId: "0001",
            //         optionId: "0001",
            //         name: "$10 dollar Voucher",
            //         type: 'voucher',
            //         index: -1,
            //         discountType: 0,
            //         value: 1,
            //         spendingAmount: 10,
            //         sku: [],
            //         available: true
            //     },
            //     {
            //         campaignId: "0002",
            //         optionId: "0002",
            //         name: "Free Karakamen",
            //         type: 'loyalty',
            //         index: 0,
            //         discountType: 2,
            //         value: 10,
            //         spendingAmount: 10,
            //         sku: ["002"],
            //         available: true
            //     }
            // ])
        }
    }, []);

    const applyItemDiscount = (item, reward) => {
        item.hasSpecialDiscount = true;
        if (reward.discountType == 2) {
            item.specialDiscount = 1;
        } else if (reward.discountType == 1) {
            item.specialDiscount = reward.spendingAmount / 100;
            item.specialDiscountAmount = 0;
        } else {
            item.specialDiscount = 0;
            item.specialDiscountAmount = reward.spendingAmount;
        }
        item.specialDiscountRemark = reward.name;
        return item;
    }
    const calculateOptionAmt = (i) => {
        let optAmt = 0;
        if (i.optionArray) {
            i.optionArray.forEach((element) => {
                if (element.optionType === 3 || element.optionType === 5) { //set meal
                    if (element.optionActive === true) {
                        element.options.forEach(function (op) {
                            if (!op.active) return;
                            optAmt = optAmt + (op && op.price ? op.price : (op.addPrice ? op.addPrice : 0));
                            op.optionArray.forEach((element) => {
                                element.options.forEach((it) => {
                                    if (it.active) {
                                        if (it.price) {
                                            optAmt += it.price * (it.qty ? it.qty : 1);
                                        } else if (it.addPrice) {
                                            optAmt += it.addPrice * (it.qty ? it.qty : 1);
                                        }
                                    }
                                })
                            });
                        });
                    }
                } else {
                    const filteredArray = element.options.filter(
                        (item) => item.active === true
                    );
                    filteredArray.forEach((item) => {
                        optAmt += item.addPrice * (item.qty ? item.qty : 1);
                    });
                }
            })
        }
        return optAmt;
    }
    const redeemReward = (reward) => {
        if (!reward.available) return;
        let rdm = redeem;
        if (!rdm) rdm = [];
        let rdmObj = {};

        for (let t of rdm) { //redeem item
            if (rdmObj[t.campaignId]) {
                rdmObj[t.campaignId] += t.value;
            } else {
                rdmObj[t.campaignId] = t.value;
            }
        }
        let dis = discount;

        let totalAmt = promotion.subTotalAmount(cart, discount);
        for (let r of rdm) { //already redeem
            if (r.campaignType !== "loyalty" && r.campaignId == reward.campaignId) {
                return;
            } else if (r.campaignType == 'loyalty' && r.optionId == reward.optionId) {
                return;
            }
        }
        if (!userInfo[reward.campaignId]) return;
        let pts = userInfo[reward.campaignId] - (rdmObj[reward.campaignId] ? rdmObj[reward.campaignId] : 0);

        if (pts < reward.value) return; //not enough points

        if (!dis) {
            dis = {
                hasFurtherDiscount: false,
                furtherDiscount: 0,
                furtherDiscountAmount: 0,
                furtherDiscountRemark: ""
            }
        }
        if (reward.index == -1) {//overall
            if (reward.discountType == 0) {
                dis.hasFurtherDiscount = true;
                dis.furtherDiscount = 0;
                dis.furtherDiscountAmount += reward.spendingAmount;
                dis.furtherDiscountAmount = Math.min(totalAmt, dis.furtherDiscountAmount);
                dis.furtherDiscountRemark = reward.name;
            } else {
                dis.hasFurtherDiscount = true;
                dis.furtherDiscount = reward.spendingAmount / 100;
                dis.furtherDiscountAmount = 0
                dis.furtherDiscountRemark = reward.name;
            }
            if (reward.type == 'cashback') {
                rdm.push({
                    campaignId: reward.campaignId,
                    optionId: reward.optionId,
                    campaignType: reward.type,
                    name: reward.name,
                    value: Math.min(totalAmt, reward.spendingAmount),
                    spendingAmount: Math.min(totalAmt, reward.spendingAmount),
                    index: -1
                });
            } else {
                rdm.push({
                    campaignId: reward.campaignId,
                    optionId: reward.optionId,
                    campaignType: reward.type,
                    name: reward.name,
                    value: reward.value,
                    spendingAmount: Math.min(totalAmt, reward.spendingAmount),
                    index: -1
                });
            }
            console.log(finalOrder);
            finalOrder.discount = dis;
            finalOrder.redeem = rdm;
            finalOrder.data = cart;
            setFinalOrder(finalOrder);
            localStorage.setItem("finalOrder", JSON.stringify(finalOrder));
            setDiscount(JSON.parse(JSON.stringify(dis)));
            setRedeem(rdm);
            addToCart(cart);
            setShow(false);
            setDisplay("none");
        } else {
            let i = 0;
            for (let it of cart) {
                let optAmt = calculateOptionAmt(it);
                if (optAmt > 0) continue;
                if (it.hasSpecialDiscount) continue;
                
                if (reward.sku.indexOf(it.codeId) > -1) {
                    if (it.qty > 1) {
                        it.qty = it.qty - 1;
                        let newItem = JSON.parse(JSON.stringify(it));
                        newItem.qty = 1;
                        applyItemDiscount(newItem, reward);
                        console.log(newItem);
                        cart.splice(i, 0, newItem);
                    } else {
                        applyItemDiscount(it, reward);
                    }
                    rdm.push({
                        campaignId: reward.campaignId,
                        optionId: reward.optionId,
                        campaignType: reward.type,
                        name: reward.name,
                        value: reward.value,
                        spendingAmount: reward.spendingAmount,
                        index: i
                    });
                    finalOrder.redeem = rdm;
                    finalOrder.data = cart;
                    console.log(finalOrder);
                    setFinalOrder(finalOrder);
                    localStorage.setItem("finalOrder", JSON.stringify(finalOrder));
                    setDiscount(JSON.parse(JSON.stringify(dis)));
                    setRedeem(rdm);
                    addToCart(cart);
                    setShow(false);
                    setDisplay("none");
                    return;
                }
                i++;
            }
        }
    };

    // if (!adCustomer) {
    //     return <></>;
    // }
    return (
        <React.Fragment>
            <ModalContainer display={displayShow}>
                <IconButton onClick={() => {
                    setShow(false);
                    setDisplay(false);
                }}>
                    <IoCloseCircle size={36} />
                </IconButton>
                <ContentWrapper>
                    <Title style={{ marginTop: 16, marginBottom: 4 }}>Available Rewards</Title>
                    <CContainer>
                        {campaign && campaign.map((c, i) =>
                            <CampaignWrapper
                                key={i}
                                onClick={() => { redeemReward(c); }}
                                isValid={c.available}>
                                <Summary type={c.type}>
                                    {c.discountType == 2 ? "FREE" : c.discountType == 1 ? c.spendingAmount + "%" : "$" + c.spendingAmount}
                                </Summary>
                                <CType type={c.type}>
                                    {c.type}
                                </CType>
                                <Description>
                                    {c.name}
                                </Description>
                            </CampaignWrapper>
                        )}
                    </CContainer>
                </ContentWrapper>
            </ModalContainer>
        </React.Fragment >
    );
}

export default AdvocadoPage;

const Title = styled.div`
    font-size: 16px;
    font-weight: 400;
`;
const Summary = styled.div`
    font-weight: 700;
    width: 65px;
    padding-left: 8px;
    border-right: 1px solid black;
    display: flex;
    align-items: center;
`;
const CContainer = styled.div`
    flex-grow:1;
    width: 100%;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
`;
const CampaignWrapper = styled.div`
    display: flex;
    position: relative;
    width: 95%;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.4);
    margin: 0px 4px;
    margin-top: 16px;
    opacity:${(props) => props.isValid ? 1 : 0.4};
`;
const CType = styled.div`
    position: absolute;
    top: -8px;
    left: -4px;
    color: white;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.4);
    background: ${(props) => {
        switch (props.type) {
            case "loyalty":
                return "#4caf50";
            case "voucher":
                return "#f44336";
        }
    }};
`;
const Description = styled.div`
    flex-grow:1;
    padding: 16px 4px;
    padding-left: 8px;
    width: 100%;
`;

const IconButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  background: white;
  z-index: 99;
`;
const ContentWrapper = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    overflow-y: auto;
    margin-top: 0px;
`;
const ModalContainer = styled.div`
    display: ${(props) => props.display};
    position: fixed;
    flex-direction: column;
    background: #fff;
    top: 0;

    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 10;

    animation: modalOrder .1s;
    animation-timing-function: ease-out;
    @keyframes modalOrder {
        0%  {
        transform: translateY(350px);
        opacity: 0;
        }
        
        100%  {
        transform: translateY(0px);
        opacity: 1;
        }
        
    }
  `;

